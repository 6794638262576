@font-face {
  font-family: "tkt";
  src: url("/public/font/TKT-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("/public/font/Poppins-Regular.ttf") format("truetype");
}


.custom-rounded {
  border-radius: 10px;
}

.loader-container {
  width: 100%;
  height: 100vh;
  background-color: white;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000 !important;
}

.loader {
  width: 70px;
  height: 70px;
  border: 5px solid;
  color: #6dd377;
  border-radius: 50%;
  border-top-color: transparent;
  animation: loader 1.2s infinite;
}

@keyframes loader {
  to {
    transform: rotate(360deg);
  }
}


.bg {
  width: 100%;
  height: 100vh;
  background-size: cover;
}

.nav-link {
  position: relative;
  text-decoration: none;
  color: black;
  overflow: hidden;
}

.nav-link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0px;
  left: 0;
  background-color: #6ed377;
  transition: transform 0.3s ease, left 0.3s ease;
  transform-origin: right;
  transform: scaleX(0);
}

.nav-link:hover::before {
  left: 0;
  transform: scaleX(1);
  animation: slideFromLeft 0.3s forwards;
}

@keyframes slideFromLeft {
  from {
    transform: scaleX(0);
    left: 100%;
  }
  to {
    transform: scaleX(1);
    left: 0;
  }
}

.nav-link:not(:hover)::before {
  animation: slideToRight 0.3s forwards;
}

@keyframes slideToRight {
  from {
    transform: scaleX(1);
    left: 0;
  }
  to {
    transform: scaleX(0);
    left: 100%;
  }
}

@keyframes slideFromLeft {
  from {
    transform: scaleX(0);
    left: 100%;
  }
  to {
    transform: scaleX(1);
    left: 0;
  }
}

nav {
  z-index: 100 !important;
  width: 100%;
}

.burgerNav {
  display: flex;
  flex-direction: column;
  color: white;
  gap: 15px;
}

.leftNav {
  width: 60%;
  height: 100vh;
  max-width: 60%;
  max-height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: -60%; /* Updated value to half of the screen */
  transition: right 0.3s ease-in-out;
  z-index: 99;
}

.leftNav.active {
  right: 0;
}

.main {
  width: 100%;
  height: 86.9vh;
  align-items: center;
  justify-content: center;
}

.aboutMain {
  width: 100%;
  height: auto;
  align-items: center;
  display: flex;
  background-color: white;
  gap: 80px;
  padding-top: 80px;
  padding-bottom: 80px;
}

.custom-heading {
  color: black;
  font-weight: bold;
  font-size: 5rem;
  line-height: 1.5;
}

.custom-img{
  width: 700px;
  height: auto;
  background-size: cover;
  margin-right: 40px;
  border-radius: 15px;
}

.custom-div{
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  gap: 10px;
}



.custom-div h1{
  font-size: 80px;
}


.our-vision {
  display: flex;
  background-color: #f9fafb;
  flex-direction: column;
  gap: 5px;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 100px;
}

.our-vision h5 {
  font-size: 40px;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.our-vision h6 {
  font-size: 18px;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  font-weight: 500;
}

.team_members_count {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.team_members_count h5 {
  font-size: 40px;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.team_members_count h6 {
  font-size: 18px;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  font-weight: 500;
}


@keyframes levitateRotate {
  0% {
    transform: translateY(0) rotate(10deg);
  }
  50% {
    transform: translateY(-12px) rotate(10deg);
  }
  100% {
    transform: translateY(0) rotate(10deg);
  }
}

.levitating-img {
  animation: levitateRotate 3s ease-in-out infinite;
  width: 21rem;
  height: auto;
  margin-right: 150px;
  filter: drop-shadow(10px 10px 1px #222);
}

.hero {
  width: 100%;
  height: 89.6vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.download {
  display: flex;
  margin-top: 30px;
  gap: 20px;
}

.how {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 115px;
  padding-bottom: 115px;
}

.home4 {
  width: 100%;
  height: 650px;
  background-color: white;
}

.notify {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 90deg;
  inherits: true;
}

@property --gradX {
  syntax: "<percentage>";
  initial-value: 50%;
  inherits: true;
}

@property --gradY {
  syntax: "<percentage>";
  initial-value: 0%;
  inherits: true;
}

:root {
  --d: 2500ms;
  --angle: 90deg;
  --gradX: 100%;
  --gradY: 50%;
  --c1: #425f57;
  --c2: #47b850;
}

.wrapper {
  min-width: min(40rem, 100%);
}

.box {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url(assets/bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  gap: 40px;
  /* margin: max(1rem, 3vw); */
  padding: max(1rem, 3vw);
  border: 0.35rem solid;
  /* padding: 3vw; */
  border-image: conic-gradient(
      from var(--angle),
      var(--c2),
      var(--c1) 0.1turn,
      var(--c1) 0.15turn,
      var(--c2) 0.25turn
    )
    30;
  animation: borderRotate var(--d) linear infinite forwards;
}

.box:nth-child(2) {
  border-image: radial-gradient(
      ellipse at var(--gradX) var(--gradY),
      var(--c1),
      var(--c1) 10%,
      var(--c2) 40%
    )
    30;
  animation: borderRadial var(--d) linear infinite forwards;
}

@keyframes borderRotate {
  100% {
    --angle: 420deg;
  }
}

@keyframes borderRadial {
  20% {
    --gradX: 100%;
    --gradY: 50%;
  }
  40% {
    --gradX: 100%;
    --gradY: 100%;
  }
  60% {
    --gradX: 50%;
    --gradY: 100%;
  }
  80% {
    --gradX: 0%;
    --gradY: 50%;
  }
  100% {
    --gradX: 50%;
    --gradY: 0%;
  }
}

.email {
  display: flex;
  gap: 20px;
}

.box h1 {
  color: white;
  font-size: 50px;
  font-weight: 900;
  text-align: center;
}

input {
  border: 0;
  border-radius: 8px;
  outline: 0;
  padding: 10px 15px;
  height: 50px;
  width: 320px;
  font-size: 13px;
}

/* .email button {
  width: 120px;
  height: 50px;
  border: 0;
  color: white;
  border-radius: 8px;
  background-color: #548c69;
  font-size: 15px;
  font-weight: 900;
  cursor: pointer;
  transition: all 0.1s ease;
} */

/* .email button:active {
  transform: scale(0.9);
} */

#error-message {
  color: red;
  font-size: 14px;
  text-align: center;
}

.product_card {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 55px;
}

.pr_card {
  display: flex;
  align-items: center;
}

.fafa_container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.fafa_shadow {
  width: 250px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.059);
  margin-top: 10px;
}

.fafa {
  width: 250px;
  height: 350px;
  background-image: url(assets/Fafa.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.pop-up {
  width: 185px;
  height: 50px;
  border-radius: 50px;
  background-color: white;
  position: absolute;
  left: 120px;
  top: 30px;
  display: flex;
  align-items: center;
  gap: 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.63);
}

.veg-txt {
  font-size: 15.5px;
  color: rgba(0, 0, 0, 0.541);
}

.green-circle {
  width: 30px;
  height: 30px;
  background-color: #47b850;
  border-radius: 50%;
  margin-left: 10px;
}

.pr_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.pr_text h1 {
  font-size: 30px;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  text-decoration: none;
  font-weight: 900;
}

.pr_text h2 {
  width: 600px;
  font-size: 17px;
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
  text-decoration: none;
  font-weight: 500;
}

.container {
  max-width: 1170px;
  margin: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
}

.footer {
  background-color: #24262b;
  padding: 70px 0;
  font-family: "Poppins-Regular" 
}
.footer-col {
  width: 25%;
  padding: 0 15px;
}
.footer-col h4 {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}
.footer-col h4::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #6dd377;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}
.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}
.footer-col ul li a {
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  display: block;
  transition: all 0.3s ease;
}

.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}
.footer-col .social-links a:hover {
  color: #24262b;
  background-color: #ffffff;
}

.developed img {
  width: 200px;
  height: auto;
}

@media screen and (max-height: 950px) {
  .levitating-img {
    width: 17rem;
    height: auto;
  }
  .custom-heading {
    font-size: 4rem;
  }
  
}

@media screen and (max-width: 1100px) {
  .hero {
    flex-direction: column;
    text-align: center;
    height: auto;
  }
  .custom-heading {
    font-size: 3.5rem;
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .download {
    justify-content: center;
  }
  .levitating-img {
    width: 16rem;
    margin-right: 0;
    margin-top: 30px;
    filter: drop-shadow(0px 0px 0px #ffffff);
  }
  .how {
    margin-top: 250px;
  }
  @keyframes levitateRotate {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-12px);
    }
    100% {
      transform: translateY(0);
    }
  }
  .email {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .box h1 {
    font-size: 30px;
  }
  .product_card {
    flex-direction: column;
    gap: 15px;
    padding-bottom: 100px;
  }
  .pr_text h1 {
    font-size: 19px;
  }
  .pr_text {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .pr_text h2 {
    width: 450px;
    font-size: 16px;
  }

  .pr_card {
    justify-content: center;
    align-items: center;
  }

  .pop-up {
    left: 95px;
  }

  .footer-col {
    width: 50%;
    margin-bottom: 30px;
  }
  .aboutMain{
    flex-direction: column;
    gap: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .custom-img{
    margin-right: 0;
    width: 600px;
  }
  .custom-div h1{
    font-size: 80px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .custom-div{
    width: 100%;
    margin-left: 0;
    align-items: center;
    text-align: center;
    margin-top: 10px;
  }
  .custom-div h2{
    display: none;
  }
  .our-vision{
    padding-left: 15px;
    justify-content: center;
    padding-right: 15px;
  }
}

@media screen and (max-width: 500px) {
  .custom-heading {
    font-size: 3rem;
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .how {
    margin-top: 250px;
  }
  .pr_text h2 {
    width: auto;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 16px;
  }
  .levitating-img {
    margin-top: 50px;
  }
  .footer-col {
    width: 100%;
  }
  .custom-div h1{
    font-size: 70px;
  }
  .custom-div p {
    padding-left: 10px;
    padding-right: 10px;
  }
  .custom-img{
    border-radius: 0;
  }
  
}

@media screen and (max-width: 380px) {
  .how {
    margin-top: 500px;
  }
  .levitating-img {
    margin-top: 50px;
  }
  .faq{
    margin-top: 80px;
  }
}
